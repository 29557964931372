import Accordion from './accordion'

const init = function () {

    const $accordionFilter = $('.accordions-wrapper');

    $accordionFilter.each((index, element) => {
        let accordion = new Accordion($(element));
        accordion.init()
    })

}

export default init