import Scrollsnap from '../components/scrollsnap'

const init = function () {

    const $heroContainer = $('#hero-slider-container');

    if ($heroContainer.length) {
        const fullpageLicenseKey = $heroContainer.data('key');

        let scrollSnap = new Scrollsnap($heroContainer, {
            navigation: false,
            scrollingSpeed: 800,
            touchSensitivity: 10,
            scrollOverflow: true,
            licenseKey: fullpageLicenseKey,
            normalScrollElements: '.content-expanded',

            afterRender: function () {
                $("#hero-slide-1").removeClass("load");
                $("#hero-slide-1").addClass("init");

                $(".nav-elements").removeClass('resting-state');
                $(".nav-elements").addClass('active-state');

                $(".background-animation").removeClass('load');
                $(".background-animation").addClass('active');

            },

            onLeave: function (origin, destination, direction) {

                if ($("#hero-slide-1").hasClass("init")) {

                    $("#hero-slide-1").removeClass("init");
                }

                let delay = 0, slides = $(".background-animation");

                slides.each(function () {

                    let imgContainer = $(this).find(".blind-container");
                    imgContainer.removeClass('position-' + (origin.index + 1));

                    setTimeout(function () {

                        imgContainer.addClass('position-' + (destination.index + 1));

                    }, delay);

                    delay += 75;
                });

            }

        })

        scrollSnap.init()
    }
}

export default init


