class accordion {

    constructor(container) {
        this.$window = $(window);
        this.$container = container;
        this.choicesActiveClass = 'active';
        this.$primaryElements = this.$container.find('.location-categories');
        this.allPlacesContainer = this.$container.find('.places-container');
    }

    init() {
        this.bindDropdownEvents()
    }

    bindDropdownEvents() {

        let self = this;

        self.$primaryElements.each((index, element) => {
            let $currentElement = $(element),
                $filterChoices = $currentElement.find('.places-container'),
                $mainAnchor = $currentElement.find('a'),
                accordionActive = $currentElement.hasClass(self.choicesActiveClass);

            if ($filterChoices) {

                if(accordionActive) {
                    $filterChoices.show()
                } else {
                    $filterChoices.hide()
                }

                $mainAnchor.click(() => {

                    const currentLocationCategory = $mainAnchor.parent();

                    if (currentLocationCategory.hasClass(self.choicesActiveClass)) {
                        accordionActive = false
                        $mainAnchor.removeClass('active-sign');
                        const currentFilterChoices = $(currentLocationCategory).find('.places-container');
                        currentFilterChoices.hide()
                        currentLocationCategory.removeClass(self.choicesActiveClass)

                    } else {

                        const activeLocationCategory = self.$container.find('.' + self.choicesActiveClass);
                        activeLocationCategory.removeClass(self.choicesActiveClass);
                        const activeMainAnchor = $(activeLocationCategory).find('.active-sign');
                        activeMainAnchor.removeClass('active-sign');
                        const $activeFilterChoices = activeLocationCategory.find('.places-container');
                        $activeFilterChoices.hide();
                        $mainAnchor.addClass('active-sign');
                        accordionActive = true;
                        $filterChoices.show();
                        $currentElement.addClass(self.choicesActiveClass);
                    }
                })
            }
        })
    }
}

export default accordion
