import Join from '../components/join-thelist-btn'

const init = function () {

    const $joinWrapper = $('.join-the-list'),
        $contactButton = $('.contact-button');

    const join = new Join($joinWrapper, $contactButton);
    join.init()
}

export default init
