class Join {

    constructor(container, $contactButton = null) {
        this.expanded = false
        this.$body = $('body')
        this.$html = $('html')
        this.$window = $(window)
        this.$container = container
        this.$contactButton = $contactButton;
    }

    init() {
        this.bindContactButtonEvent()
    }

    bindContactButtonEvent() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const type = urlParams.get('type')


        this.$contactButton.on('click', () => {
            const unitType = this.$contactButton.data('unit');
            window.location.href = '/contact?type=' + unitType;
        });

        // We use an external form, this form is added on the fly, And this is to load the selected option when
        // the form is loaded
        $('.contact-form').bind('DOMSubtreeModified', function () {
            $(`option[value="${type}"]`).attr('selected', 'selected')
        })

    }
}

export default Join
