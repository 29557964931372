class Filter {

    constructor(container) {
        this.$container = $(container);
        this.$filterOptions = this.$container.find('.filter-items');
        this.$mobileOptions = this.$container.find('.mobile-options');
        this.$floorplanCards = this.$container.find('.floorplan-card');
    }

    init() {
        this.bindFilteringEvent();
        this.bindSelectEvent();
        this.bindSaveFilter();
        this.applyFilter();
    }

    bindSelectEvent() {
        
        this.$mobileOptions.on('change', (event) => {

            const filter = event.currentTarget.value;

            if (filter === 'all') {
                this.$floorplanCards.show();
            } else {
                this.$floorplanCards.each((index, floorplanCard) => {
                    const $floorplanCard = $(floorplanCard);

                    if ($floorplanCard.data('filter') === filter) {
                        $floorplanCard.show();
                    } else {
                        $floorplanCard.hide();
                    }
                })
            }
        });
    }

    bindFilteringEvent() {

        this.$filterOptions.on('click', (event) => {
            const $currentOption = $(event.currentTarget);
            const currentFilter = $currentOption.data('filter');
            this.$filterOptions.removeClass('active');
            $currentOption.addClass('active');

            if (currentFilter === 'all') {
                this.$floorplanCards.show();
            } else {
                this.$floorplanCards.each((index, floorplanCard) => {
                    const $floorplanCard = $(floorplanCard);

                    if ($floorplanCard.data('filter') === currentFilter) {
                        $floorplanCard.show();
                    } else {
                        $floorplanCard.hide();
                    }
                })
            }
        });
    }

    bindSaveFilter() {
        const $viewPlanLink = this.$floorplanCards.find('.view-plan');

        $viewPlanLink.on('click', () => {
            const filter = this.$container.find('.active').data('filter');
            sessionStorage.setItem('filter', filter);
        })
    }

    applyFilter() {
        const storageFilter = sessionStorage.getItem('filter');

        if (storageFilter) {
            this.$filterOptions.removeClass('active');

            this.$filterOptions.each((index, currentOption) => {

                const $currentOption = $(currentOption),
                    optionFilter = $currentOption.data('filter');

                if (optionFilter === storageFilter) {
                    $currentOption.addClass('active');

                    if (storageFilter === 'all') {
                        this.$floorplanCards.show();
                    } else {
                        this.$floorplanCards.each((index, floorplanCard) => {
                            const $floorplanCard = $(floorplanCard);

                            if ($floorplanCard.data('filter') === storageFilter) {
                                $floorplanCard.show();
                            } else {
                                $floorplanCard.hide();
                            }
                        })
                    }
                }
            });

            this.$mobileOptions.val(storageFilter);
        }
    }
}

export default Filter;