import Slider from '../components/slider'

const init = function () {

    const $sliderWithText = $('.slider-with-text');

    if($sliderWithText){
        $sliderWithText.each((index, element) => {
            let slider = new Slider($(element), {});
            slider.init();
        })
    }
};

export default init