let Isotope = require('isotope-layout');
let imagesLoaded = require('imagesloaded')

class LoadMore {

    constructor(loadMoreButton, $entriesContainer){
        this.loadMoreButton = loadMoreButton;
        this.$entriesContainer = $entriesContainer;
    }

    init() {
        this.bindAjaxCall();
    }

    bindAjaxCall() {

        let self = this;

        this.loadMoreButton.on('click', function () {

            let currentPage = $(this).data('current-page'),
                maxPage = $(this).data('max-page');
            currentPage++;

            $.ajax({
                url: ajaxurl,
                data: {
                    'page': currentPage,
                    'action': 'load_more',
                },
                type: 'POST',
                success: function (response) {

                    let data = JSON.parse(response);

                    if (data.htmlContent) {

                        self.$entriesContainer.after(data.htmlContent);
                        let grid = document.querySelector('.new-block');

                        let iso = new Isotope( grid, {
                            // options...
                            itemSelector: '.grid-item',
                            percentPosition: true,
                            masonry: {
                                columnWidth: 100,
                            }
                        });

                        imagesLoaded( grid ).on( 'progress', function() {
                            // layout Isotope after each image loads
                            iso.layout();
                        });

                        if (currentPage < maxPage) {
                            self.loadMoreButton.data('current-page', currentPage)
                        } else {
                            self.loadMoreButton.hide();
                        }
                    }
                }
            });
        });
    }
}

export default LoadMore
