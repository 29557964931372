import throttle from "lodash/throttle";

class Scrollsnap {

    constructor($container, config) {
        this.config = config;
        this.$container = $container;
        this.height = $(window).height();
        this.navElements = $('.nav-elements');
        this.firstSlide = $('#hero-slide-1')[0];
        this.containerId = '#hero-slider-container';
        this.backgroundAnimation = $('.background-animation');
        this.$backgroundContainer = $('#background-animation-container');
    }

    init() {

        this.bindOnResize();

        if (this.height <= 450) {
            this.$backgroundContainer.hide();
            this.$container.find('.hero-slide').addClass('show');
            this.$container.find('.heading').addClass('show');
            this.$container.find('.description').addClass('show');
            this.$container.find('.button').addClass('show');

        } else {

            $('.default-nav').css('display', 'none');
            this.loadAnimation();
            $('.hero-slide').css('background-image', 'none');
            new fullpage(this.containerId, this.config);
        }
    }

    loadAnimation() {

        if ($(this.firstSlide).hasClass('load')) {
            $(this.navElements).addClass('resting-state');
            this.backgroundAnimation.addClass('load');
        }
    }

    bindOnResize() {

        $(window).on('resize', throttle(() => {
            window.location.reload();
        }, 75));

    }
}

export default Scrollsnap
