class Menu {

    constructor(container) {
        this.expanded =  false
        this.$body = $('body')
        this.$html = $('html')
        this.$window = $(window)
        this.$container = container
        this.$section = this.$body.find('section')
        this.$hero = this.$body.find('.hero')
        this.$navigation = this.$body.find('.navigation')
        this.$menuElements = this.$container.find('.menu-elements')
        this.expandedClass = 'expanded'
        this.hideClass = 'hide'
        this.backgroundClass = 'scroll-animation'
        this.$menuContainer = this.$container.find('.expand-menu-btn')
        this.menuActiveClass = 'active'
        this.$contentExpanded = this.$body.find('.content-expanded')
        this.$contentHide = this.$body.find('.content-hide')
        this.$joinBtnClose = this.$body.find('.close')
        this.expandedClass = 'expanded'
        this.hideClass = 'hide'
        this.$closeSmall = this.$body.find('.close-small-only')
        this.$joinBtn = this.$body.find('.join-the-list')
    }

    init(){
        this.menuEvents()
        this.scrollEvents()
    }

    scrollEvents(){
        window.addEventListener('scroll', () => {
            let navigationHeight = this.$navigation.height()
            let heroHeight = this.$hero.height()
            let scrollPosition = this.$window.scrollTop()

            scrollPosition >= heroHeight - navigationHeight ? this.$navigation.addClass(this.backgroundClass) : this.$navigation.removeClass(this.backgroundClass)
        })
    }

    menuEvents(){
        this.$menuContainer.on('click', () => {
            if (!this.expanded) this.expandMenu()
            else this.contractMenu()
            this.expanded = !this.expanded
        })
    }

    expandMenu() {
        setTimeout(() => {
            this.$container.addClass(this.expandedClass)
            this.$menuContainer.addClass(this.menuActiveClass)
            this.$menuElements.addClass(this.expandedClass)
        }, 5)
        this.$html.css('overflow', 'hidden')
        this.$menuElements.css('display', 'flex')
        this.$joinBtn.css('display' , 'none')
    }

    contractMenu() {
        setTimeout(() => {
            this.$container.removeClass(this.expandedClass)
            this.$menuContainer.removeClass(this.menuActiveClass)
            this.$menuElements.removeClass(this.expandedClass)
            this.$joinBtn.css('display', 'block')
        }, 5)
        this.$html.css('overflow', 'auto')

        setTimeout(() => {
            this.$menuElements.css('display', 'none')
        }, 500)
    }
}

export default Menu
