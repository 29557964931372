import Filter from '../components/filter';

const init = function() {

    const $filterSection = $('.floorplans-filter');

    if($filterSection) {
        $filterSection.each((index, element) => {
            const filter = new Filter(element);
            filter.init();
        });
    }
}

export default init