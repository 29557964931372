let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

class Map {

    constructor(container, config) {
        this.map = '';
        this.zoom = config.zoom;
        this.container = container;
        this.places = config.places;
        this.styleUrl = config.styleUrl;
        this.maxBounds = config.maxBounds;
        this.minBounds = config.minBounds;
        this.mapCenter = config.mapCenter;
        this.interactive = config.interactive;
        this.accessToken = config.accessToken;
        this.serifLocation = config.serifLocation;
    }

    init() {
        this.instantiateMap();
        this.addPlaces();
        this.addSerifLocation();
        this.bindHoverEvents();
        this.bindHoverLocationsEvents();
        this.bindOnClickEvent();
    }

    instantiateMap() {

        mapboxgl.accessToken = this.accessToken;
        this.map = new mapboxgl.Map({
            zoom: this.zoom,
            style: this.styleUrl,
            center: this.mapCenter,
            container: this.container,
            interactive: this.interactive,
        });
    }

    addSerifLocation() {
        const coordinates = $(this.serifLocation).data('coordinates');

        new mapboxgl.Marker(this.createPlaceMarker('marker-serif-place'))
            .setLngLat(coordinates)
            .addTo(this.map);
    }

    addPlaces() {
        let self = this;

        this.places.each((index, element) => {

            let coordinates = $(element).data('coordinates'),
                viewIndex = index + 1;

            if (viewIndex <= 9) {
                viewIndex = '0' + viewIndex;
            }

            const placeName = element.textContent;

            $(element).prepend(`<span class="place-index">${viewIndex}.</span>`)
                .addClass('index-' + viewIndex)
                .attr('data-index', viewIndex);

            new mapboxgl.Marker(self.createPlaceMarker('marker-place', viewIndex, placeName))
                .setLngLat(coordinates)
                .addTo(self.map);
        });
    }

    bindHoverEvents() {

        $('.marker-place').each((index, element) => {

            $(element).mouseenter(function () {

                const index = $(this).data('index');

                $('.index-' + index).addClass('marker-hover').removeClass('black');

            });

            $(element).mouseleave(function () {

                const index = $(this).data('index');

                $('.index-' + index).removeClass('marker-hover').addClass('black');

            })
        });
    }

    bindHoverLocationsEvents() {

        let $markerPlaces = $('.marker-place');

        $('.locations-place').each((i, locationName) => {

            $(locationName).mouseenter(function () {

                const index = $(this).data('index');

                $markerPlaces.each((i, marker) => {

                    if (marker.textContent == index) {
                        $(marker).parent().addClass('highlighted');
                    }

                })
            });

            $(locationName).mouseleave(function () {

                $markerPlaces.parent().removeClass('highlighted');

            })
        });
    }

    bindOnClickEvent() {

        let $markerPlaces = $('.marker-place');

        $('.locations-place').each((i, locationName) => {

            $(locationName).on('touchstart', function () {

                $markerPlaces.parent().removeClass('highlighted');

                if($(locationName).hasClass('marker-hover')) {

                    $(locationName).removeClass('marker-hover').addClass('black');

                } else {

                    $('.locations-place').removeClass('marker-hover').addClass('black');

                    $(locationName).addClass('marker-hover').removeClass('black');

                    const index = $(this).data('index');

                    $markerPlaces.each((i, marker) => {

                        if (marker.textContent == index) {
                            $(marker).parent().addClass('highlighted');
                        }
                    })
                }
            });
        });
    }

    createPlaceMarker(cssClass, content = null, placeName = null) {
        let marker = document.createElement('div');
        marker.className = cssClass;

        if (content != null && placeName != null) {
            let container = document.createElement('div');
            container.className = 'marker-container';

            let placeNameElement = document.createElement('div');
            placeNameElement.className = 'place-name';
            placeNameElement.textContent = placeName;

            marker.textContent = content;
            $(marker).data('index', content);
            $(container).append(placeNameElement);
            $(container).append(marker);

            return container;

        }

        return marker;
    }
}

export default Map;