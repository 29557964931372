import Slider from '../components/slider'

const init = function () {

    const $twoColumnsSlider = $('.two-column-slider-wrapper');

    if($twoColumnsSlider){
        $twoColumnsSlider.each((index, element) => {
            let slider = new Slider($(element), {});
            slider.init();
        })
    }
};

export default init