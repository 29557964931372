import Menu from '../components/menu'

const init = function () {

    const $navigationWrapper = $('.navigation')

    const menu = new Menu($navigationWrapper)
    menu.init()
}

export default init
