let THREE = require('three');

class ImageViewer {

    constructor(element) {
        this.lon = 0;
        this.lat = 0;
        this.phi = 0;
        this.mesh = '';
        this.theta = 0;
        this.scene = '';
        this.camera = '';
        this.geometry = '';
        this.material = '';
        this.renderer = '';
        this.container = '';
        this.element = element;
        this.onMouseDownLon = 0;
        this.onMouseDownLat = 0;
        this.document = document;
        this.onMouseDownMouseX = 0;
        this.onMouseDownMouseY = 0;
        this.isUserInteracting = false;
        this.animate = this.animate.bind(this);
        this.overlay = $(element).parent().find('.overlay-container')[0];
    }

    init() {

        this.container = document.getElementById($(this.element).attr('id'));
        this.camera = new THREE.PerspectiveCamera( 75, $(this.element).width() / $(this.element).height(), 1, 1100 );
        this.scene = new THREE.Scene();
        this.geometry = new THREE.SphereGeometry( 500, 60, 40 );
        this.material = new THREE.MeshBasicMaterial( {
            map: new THREE.TextureLoader().load($(this.element).data('image'))
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.renderer = new THREE.WebGLRenderer();

        this.camera.target = new THREE.Vector3(-456, 0, 0);
        this.geometry.scale(-1, 1, 1);
        this.geometry.rotateY(3.14159);
        this.scene.add(this.mesh);

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize($(this.element).width(), $(this.element).height());
        this.container.appendChild( this.renderer.domElement );

        this.bindOverlayListener();
        this.bindOnMouseDownEvent();
        this.bindOnMouseUpEvent();
        this.bindOnMouseMoveEvent();
        this.bindOnTouchStartEvent();
        this.bindOnTouchEndEvent()
        this.bindOnTouchMoveEvent();
        this.bindOnWindowResizeEvent();

    };

    bindOverlayListener() {

        this.overlay.addEventListener('click', (event) => {
            event.preventDefault();
            $(this.overlay).fadeOut();
        })
    }

    bindOnMouseDownEvent() {

        this.container.addEventListener('mousedown', (event) => {

            event.preventDefault();

            this.isUserInteracting = true;

            this.onPointerDownPointerX = event.clientX;
            this.onPointerDownPointerY = event.clientY;

            this.onPointerDownLon = this.lon;
            this.onPointerDownLat = this.lat;

        }, false);
    }

    bindOnMouseUpEvent() {

        this.container.addEventListener('mouseup', (event) => {

            this.isUserInteracting = false;

        }, false)
    }

    bindOnMouseMoveEvent() {
        this.container.addEventListener('mousemove', (event) => {
            if (this.isUserInteracting === true) {

                this.lon = ( this.onPointerDownPointerX - event.clientX ) * 0.1 + this.onPointerDownLon;
                this.lat = ( event.clientY - this.onPointerDownPointerY ) * 0.1 + this.onPointerDownLat;

            }
        }, false)
    }

    bindOnTouchStartEvent() {

        this.container.addEventListener('touchstart', (event) => {

            event.preventDefault();

            this.isUserInteracting = true;

            this.onPointerDownPointerX = event.changedTouches[0].clientX;
            this.onPointerDownPointerY = event.changedTouches[0].clientY;

            this.onPointerDownLon = this.lon;
            this.onPointerDownLat = this.lat;

        }, false);
    }

    bindOnTouchEndEvent() {

        this.container.addEventListener('touchend', (event) => {

            this.isUserInteracting = false;

        }, false)
    }

    bindOnTouchMoveEvent() {

        this.container.addEventListener('touchmove', (event) => {

            if (this.isUserInteracting === true) {

                this.lon = ( this.onPointerDownPointerX - event.changedTouches[0].clientX ) * 0.2 + this.onPointerDownLon;
                this.lat = ( event.changedTouches[0].clientY - this.onPointerDownPointerY ) * 0.2 + this.onPointerDownLat;

                console.log(this.lon);

            }
        }, false)
    }

    bindOnWindowResizeEvent() {

        window.addEventListener('resize', () => {
            this.camera.aspect = $(this.element).width() / $(this.element).height();
            this.camera.updateProjectionMatrix();
            this.renderer.setSize($(this.element).width(), $(this.element).height());
        }, false);
    }

    update() {

        this.lat = Math.max( - 85, Math.min( 85, this.lat ) );
        this.phi = THREE.Math.degToRad( 90 - this.lat );
        this.theta = THREE.Math.degToRad( this.lon );

        this.camera.target.x = 500 * Math.sin( this.phi ) * Math.cos( this.theta );
        this.camera.target.y = 500 * Math.cos( this.phi );
        this.camera.target.z = 500 * Math.sin( this.phi ) * Math.sin( this.theta );

        this.camera.lookAt( this.camera.target );

        this.renderer.render( this.scene, this.camera );
    }

    animate() {
        requestAnimationFrame( this.animate );
        this.update();
    }
}

export default ImageViewer



