import Glide from '@glidejs/glide'
const merge = require('webpack-merge')

class Slider {

    constructor(container, config) {
        this.config = config;
        this.$container = container;
        this.glideContainer = this.$container.find('.glide')[0];
        this.count = $(this.glideContainer).data('count');
        this.glide = null
    }

    init() {

        if(this.glideContainer){
            const defaults = {
                type: 'carousel', perView: 1,
                breakpoints: {1024: {perView: 1}, 640: {perView: 1}}
            };
            this.config = merge(defaults, this.config);
            this.glide = new Glide(this.glideContainer, this.config);

            if(this.count > 1) {
                this.glide.mount();
            }
        }
    }
}

export default Slider;

