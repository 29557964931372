let Isotope = require('isotope-layout');
let imagesLoaded = require('imagesloaded')

const init = function () {

    let grid = document.querySelector('.entries-container');

    if (grid !== null) {

        let iso = new Isotope( grid, {
            // options...
            itemSelector: '.grid-item',
            percentPosition: true,
            masonry: {
                columnWidth: 100,
            }
        });

        imagesLoaded( grid ).on( 'progress', function() {
            // layout Isotope after each image loads
            iso.layout();
        });
    }
}

export default init
