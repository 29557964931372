import Map from '../components/map';

const init = function() {

    let neighborhoodMap = $('#neighborhood-map');

    if(neighborhoodMap.length) {

        const zoom = neighborhoodMap.data('zoom'),
            long = neighborhoodMap.data('long'),
            lat = neighborhoodMap.data('lat');

        const mapboxConfig = document.getElementById('mapbox-config'),
            mapboxToken = $(mapboxConfig).data('token'),
            maxboxStyleUrl = $(mapboxConfig).data('style');


        let map = new Map('neighborhood-map', {
            zoom: zoom,
            interactive: true,
            places: $('.locations-place'),
            mapCenter: [long, lat],
            styleUrl: maxboxStyleUrl,
            accessToken: mapboxToken,
            serifLocation: document.getElementById('serif-location')
        });
        map.init();
    }
}

export default init
