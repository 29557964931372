import lightcase from 'lightcase'

const init = function () {
    $('a[data-rel^=lightcase]').lightcase({
        iframe: {
            width: 1600,
            height: 1000
        },
        onAfterCalculateDimensions: {
            qux: function () {
                closeButtonPosition()

                $(window).on('resize', function () {
                    closeButtonPosition()
                })
            }
        }
    });

    function closeButtonPosition() {

        let lightcaseContainer = $('#lightcase-content'),
            Width = $(window).width(),
            Height = $(window).height(),
            verticalOffset = 10,
            lightcaseContainerWidth = lightcaseContainer.width(),
            lightcaseContainerHeight = lightcaseContainer.height(),
            closeButton = $('.lightcase-icon-close')[0],
            Xposition = (Width * 0.5) - (lightcaseContainerWidth / 2),
            Yposition = (Height * 0.5) - (lightcaseContainerHeight / 2) - verticalOffset

        $(closeButton).css({'top': Yposition, 'right': Xposition})
    }
}

export default init
