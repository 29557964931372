import LoadMore from '../components/load-more';

const init = function() {

    const loadMoreButton = $('#load-more-posts');

    if(loadMoreButton.length) {
        let $entriesContainer = $('.entries-container'),
            loadMore = new LoadMore(loadMoreButton, $entriesContainer);
        loadMore.init();
    }
}

export default init
