import ImageViewer from '../components/image-viewer'

const init = function () {

    if ($('#three-sixty-canvas').length) {
        const imageViewer = new ImageViewer($('#three-sixty-canvas')[0]);
        imageViewer.init();
        imageViewer.animate()

    }
}

export default init