import ScrollMagic from 'scrollmagic'

const init = function () {

    let controller = new ScrollMagic.Controller()
    let elements = document.querySelectorAll('.animate')

    elements.forEach((element) => {
        let dynamicContent = element.querySelectorAll('.dynamic-animation')

        new ScrollMagic.Scene({
            triggerElement: element,
            offset: -50,
            triggerHook: 1,
        }).setClassToggle(element, 'visible').addTo(controller);

        dynamicContent.forEach((dynamic) => {
            let dynamicChilds = dynamic.children

            for(let i = 1; i <= dynamicChilds.length; i++) {
                $(dynamicChilds[i]).addClass('delay-' + i)
            }
        })
    })
}

export default init
